.toast_root{
    display: flex;
    flex-direction: row;
}

.toast_logo{
    width: 18px;
    height: 18px;
    margin-left: 5px;
    margin-top: 1px;
}

.toast_info_block{
    margin-left: 8px;
}

.toast_info_top{
    font-size: 16px;
    color: #827f7f;
}

.toast_info_bottom{
    font-size: 14px;
    margin-top: 5px;
    color: #827f7f;
}