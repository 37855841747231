.emailSubscription-root{
 // border: 1px solid #E0E0E0;
  //padding: 100px;
 // margin:20px;
}
.emailSubscription-checkboxBlock{
  margin-top: 30px;
}
.emailSubscription-subscribeBlock{
  margin-top: 40px;
}
.emailSubscription-note {
  margin-top: 10px;
  .emailSubscription-span {
    font-size: 14px;
  }
}