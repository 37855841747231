.emailBlock-root{
    display: flex;
    flex-direction: column;
}
.emailBlock-input-title{
    margin-top: 25px;
    margin-bottom: 7px;
}
.emailBlock-input-title-star{
    color: red;
    margin-left: 3px;
}
.emailBlock-input-modal{
    max-width: 450px;
    width: 350px;
    height: 35px;
    border-radius: 2px;
    border: 1px solid #E0E0E0;
    outline:none;
    padding: 3px 10px 3px 10px;
    @media screen and (max-width: 660px) {
        width: 100%;
    }
}
.emailBlock-input-modal-error{
    @extend .emailBlock-input-modal;
    border: 1px solid #e55858;
}