.subscribeBlock-root{
    display: flex;
    flex-direction: column;
}
.subscribeBlock-button{
    max-width: 110px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color:  #f9f9f9;
}

.subscribeBlock-button:hover{
    background-color:  #f2f2f2;
}
.subscribeBlock-root-have-subscription{
    margin-top: 20px;
    text-decoration: underline;
    cursor: pointer;
}