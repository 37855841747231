body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Nexa';
  src: url('./modules/Common/fonts/English/NexaBook.otf') format('opentype');
}

@font-face {
  font-family: 'Hanifonts';
  src: url('./modules/Common/fonts/Arabic/Hanifonts.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

}

@font-face {
  font-family: 'Open Sans';
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
}

@font-face {
  font-family: 'Lato';
  @import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
}

@font-face {
  font-family: 'Oswald';
  @import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');
}


@font-face {
  font-family: 'Raleway';
  @import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
}

@font-face {
  font-family: 'PlayfairDisplay';
  @import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');
}

@font-face {
  font-family: 'Merriweather';
  @import url('https://fonts.googleapis.com/css?family=Merriweather&display=swap');
}

@font-face {
  font-family: 'PTSerif';
  @import url('https://fonts.googleapis.com/css?family=PT+Serif&display=swap');
}

@font-face {
  font-family: 'NotoSerif';
  @import url('https://fonts.googleapis.com/css?family=Noto+Serif&display=swap');
}

@font-face {
  font-family: 'Lora';
  @import url('https://fonts.googleapis.com/css?family=Lora&display=swap');
}

