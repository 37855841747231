@import '../../../Common/styles/colors';

.title-block-wrapper{
    width: 100%;
    height: 50px;
    background-color: $defaultBorderColor;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.title-block-text{
    color: $darkBackground;
    font-size: 18px;
    margin-left: 20px;
}