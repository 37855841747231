@import '../../../Common/styles/colors';
@import '../../../Common/styles/align';

.personal-detail-wrapper{
    margin-left: $marginLeftSubDetail;
}

.emailBlock-root{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.emailBlock-input-title{
    margin-top: 25px;
    margin-bottom: 7px;  
}
.emailBlock-input-title-star{
    color: red;
    margin-left: 3px;
}
.emailBlock-input-block{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-right: 20px;
    .emailBlock-input{
        width: 400px;
        height: 35px;
        border-radius: 2px;
        border: 1px solid #E0E0E0;
        outline:none;
        padding: 3px 3px 3px 10px;
    }
    .emailBlock-input-error{
        @extend .emailBlock-input;
        border: 1px solid #e55858;
    }

    @media screen and (max-width: 540px) {
        .emailBlock-input{
            max-width: 400px;
        }
    }
}

.emailBlock-description{
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    @media screen and (max-width: 560px) {
        flex-direction: column;
    }
    .emailBlock-description-first{
        font-size: 15px;
    }

    .emailBlock-description-second{
        font-size: 15px;
        text-decoration: underline;
        margin-left: 5px;
        cursor: pointer;
        margin-bottom: 25px;
    }
}




