@import '../../../Common/styles/colors';
@import '../../../Common/styles/align';

.buttons-block-root{
    height: 100px;
    border-top: 1px solid $defaultBorderColor;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.buttons-block-left{
    height: 40px;
    width: 140px;
    margin-left: $marginLeftSubDetail;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    outline:none; 
    background-color: #f9f9f9;   
    cursor: pointer;
}

.buttons-block-left:hover{
    background-color: #f2f2f2;   
}

.buttons-block-right{
    height: 40px;
    width: 140px;
    margin-right: $marginLeftSubDetail;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    outline:none;
    background-color: #ffffff;
    cursor: pointer;
}

.buttons-block-right:hover{
    background-color: #f9f9f9;
}